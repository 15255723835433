<!--<template>
  <el-card class="box-card-component" style="margin-left:8px;">
    <div slot="header" class="box-card-header">
      <img src="../../../../assets/imagetwo.png">
    </div>
    <div style="position:relative;">
      <div style="padding-top:35px;" class="progress-item">
        <span>待办任务</span>
        <el-progress :percentage="70" />
      </div>
      <div class="progress-item">
        <span>待审核任务</span>
        <el-progress :percentage="18" />
      </div>
      <div class="progress-item">
        <span>已完成任务</span>
        <el-progress :percentage="12" />
      </div>
      <div class="progress-item">
        <span>已处理任务</span>
        <el-progress :percentage="100" status="success" />
      </div>
    </div>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'
import PanThumb from '@/components/PanThumb'
import Mallki from '@/components/TextHoverEffect/Mallki'

export default {
  components: { PanThumb, Mallki },

  filters: {
    statusFilter(status) {
      const statusMap = {
        success: 'success',
        pending: 'danger'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      statisticsData: {
        article_count: 1024,
        pageviews_count: 1024
      }
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  }
}
</script>

<style lang="scss" >
.box-card-component{
  .el-card__header {
    padding: 0px!important;
  }
}
</style>
<style lang="scss" scoped>
.box-card-component {
  .box-card-header {
    position: relative;
    height: 232px;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.2s linear;
      &:hover {
        transform: scale(1.1, 1.1);
        filter: contrast(130%);
      }
    }
  }
  .mallki-text {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
    font-weight: bold;
  }
  .panThumb {
    z-index: 100;
    height: 70px!important;
    width: 70px!important;
    position: absolute!important;
    top: -45px;
    left: 0px;
    border: 5px solid #ffffff;
    background-color: #fff;
    margin: auto;
    box-shadow: none!important;
    ::v-deep .pan-info {
      box-shadow: none!important;
    }
  }
  .progress-item {
    margin-bottom: 10px;
    font-size: 14px;
  }
  @media only screen and (max-width: 1510px){
    .mallki-text{
      display: none;
    }
  }
}
</style>-->

<template>
 <div style="border:1px solid #EDEDED">
    <span style="margin-left: 20px;color:#4169E1;font-weight: bold;font-size:15px;">
      <i class="el-icon-message-solid"></i>待办任务</span>
    <el-divider></el-divider>
    <div style="position:relative;margin-top:-23px;margin-left:91%;">
      <a href="javascript:void(0)" @click="skip()" style="color:#409eff;font-size:13px" >更多</a>
    </div>
  <el-table :data="list" style="width: 100%;" height = 404 
        :row-style="{height:'40px'}"
        :cell-style="{padding:'0px'}"
        :header-cell-style="{background:'#dce4f1',color:'#000000'}">
    <el-table-column label="主题" min-width="200" prop="title" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ scope.row.title}}
      </template>
    </el-table-column>
     <el-table-column label="任务类型" width="100" prop="lx">
      <template slot-scope="scope">
        <div v-if="scope.row.taskclassify === 1"> 日常任务</div>
        <div v-else-if="scope.row.taskclassify == 2"> 跨部门协同</div>
        <div v-else-if="scope.row.taskclassify == 3"> 例会项目</div>
        <div v-else-if="scope.row.taskclassify == 4"> 年度计划</div>
        <div v-else-if="scope.row.taskclassify == 5"> 专项会议</div>
      </template>
    </el-table-column>
    <el-table-column label="任务进度" prop="process" width="100" align="center">
      <template slot-scope="scope">
        {{ scope.row.process}}%
      </template>
    </el-table-column>
    <el-table-column label="任务状态" width="100" align="center" prop="status">
      <template slot-scope = "scope">
          <el-tag v-if="scope.row.status == 0" effect="dark"> 进行中</el-tag>
          <el-tag v-if="scope.row.status == 1" effect="dark" type="warning">已完成审核中</el-tag>
          <el-tag v-if="scope.row.status == 2" effect="dark" type="success">已完成</el-tag>
          <el-tag v-if="scope.row.status == -1" effect="dark" type="info">已取消</el-tag>
          <el-tag v-if="scope.row.status == 3" effect="dark" type="danger">退回</el-tag>
          <el-tag v-if="scope.row.status == 4" effect="dark" type="warning">待审核</el-tag>
          <el-tag v-if="scope.row.status == 5" effect="dark" type="danger">取消待审核</el-tag>
          <el-tag v-if="scope.row.status == 6" effect="dark" type="danger">任务退回</el-tag>
      </template>
    </el-table-column>
  </el-table>
 </div>
</template>

<script>
import {QueryPageWaitDoTasksByUserCode} from "../../../../api/oa"
export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        success: 'success',
        pending: 'danger'
      }
      return statusMap[status]
    },
    orderNoFilter(str) {
      return str.substring(0, 30)
    }
  },
  data() {
    return {
      list: [
        // {title:'现有应用系统的持续开发,探索新的需求,开发新功能',lx: '日常工作',process: 100,status: 2},
        // {title:'基础平台开发,将已自主开发的功能进行部署集成',lx: '日常工作',process: 0,status: 4},
        // {title:'兴趣小组活动组织,推动信息化建设和培训',lx: '日常工作',process: 30,status: 0},
        // {title:'设计并开发新的手机端App界面,优化通讯录机制',lx: '日常工作',process: 100,status: 2},
        // {title:'将环保设备监测接入到物联网平台',lx: '日常工作',process: 20,status: 0},
        // {title:'开发IOS手机端,实现现有Android手机端功能',lx: '日常工作',process: 100,status: 2},
        // {title:'将工作任务统计到系统中进行分配、反馈',lx: '日常工作',process: 0,status: 4},
        // {title:'规范HR各个模块之间的关联关系',lx: '日常工作',process: 60,status: 0},
        // {title:'规范考勤打卡及流程，最大化实现自动核对',lx: '日常工作',process: 0,status: 4},
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    skip()
    {
        this.$router.push({path:'/Task/WaitDoTask'});
    },
    fetchData() {
      var user = JSON.parse(window.localStorage.user);
      let para = {
              userCode: user.sub,
              pageSize: 9,
      };
      QueryPageWaitDoTasksByUserCode(para).then((res) => {
           res.data.response.data.map(item => {
                let obj = {}
                obj.title = item['WorkItem']
                obj.lx = item['CommonTaskClassifyText']
                obj.taskclassify = item['TaskClassify']
                obj.process = item['TaskProgressValue']
                obj.status = item['Status']
                this.list.push(obj)
            })
        });
    }
  }
}
</script>
<style scoped>
 /deep/.el-divider{
     margin: 2px 0;
     background: 0 0;
     border-top: 1px dashed #e8eaec;
 }
</style>

